module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  SOCKET_URL: process.env.REACT_APP_API_CP_DOMAIN,
  BACKEND_CP_URL: process.env.REACT_APP_SOCKET_URL,
  MAX_UNAPPROVED_ACCOUNTS: 3,
  enableFX: process.env.REACT_APP_ENABLE_FX === "true" || true,
  enableCrypto: process.env.REACT_APP_ENABLE_CRYPTO === "true" || false,
  enableCryptoWallets: process.env.REACT_APP_ENABLE_CRYPTO_WALLETS === "true" || false,
  defaultPortal: process.env.REACT_APP_DEFAULT_PORTAL || "FOREX",
  companyCryptoDeposit: process.env.REACT_APP_COMPANY_CRYPTO_DEPOSIT === "true" || false,
  COMPANY_WALLETS: [
    {
      name:"USDT",
      networks: [
        {
          name: "TRC20",
          address: "TQu7NqSsHASwaWpYJcsaRJah3s9VprrqNb",
          tag: "TRC20",
        },
        {
          name: "ERC20",
          address: "0x63ec92C25116E46a937694b5dDC3bd1636731Df6",
          tag: "ERC20",
        }
      ],
    },
  ],
  ENABLE_DARK_MODE: true,
  ENABLE_GLOBAL_SEARCH: false,
  LEVERAGES: [1, 50, 100],
  PLATFORMS: [
    {
      name: "Windows Desktop Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://www.metatrader5.com/i/main/metatrader-5-windows.jpg",
      isLocalImg: false,
      downloadLink: "https://download.mql5.com/cdn/web/icare.forex.limited/mt5/icareforex5setup.exe",
    },
    // {
    //   name: "MacOS Desktop Trader - MT5",
    //   logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
    //   image: "https://www.metatrader5.com/i/main/metatrader-5-windows.jpg",
    //   isLocalImg: false,
    //   downloadLink: "https://apps.apple.com/us/app/metatrader-5/id413251709",
    // },
    {
      name: "Android Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://i.ytimg.com/vi/MTLbJ5lSW9M/maxresdefault.jpg",
      isLocalImg: false,
      downloadLink: "https://download.mql5.com/cdn/mobile/mt5/android?server=IcareForex-Server",
    },
    {
      name: "IOS Trader - MT5",
      logo: "https://i0.wp.com/www.marketcalls.in/wp-content/uploads/2012/01/metatrader-logo.png",
      image: "https://www.ifcmarkets.ae/images/front/platforms/MT4-MT5-mobile-ios.png",
      isLocalImg: false,
      downloadLink: "https://download.mql5.com/cdn/mobile/mt5/ios?server=IcareForex-Server",
    },
  ],
};
