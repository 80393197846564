/* eslint-disable indent */
export const JClickHandler =
  (step, stages, dispatch, toggleCurrentModal, subPortal, portal = "FOREX", extraParams = {}) => () => {
    if (!stages?.loaded) {
      // eslint-disable-next-line no-console
      console.error("Stages not loaded yet.");
      return;
    }
    console.log(step, stages, subPortal, portal, extraParams);
    // eslint-disable-next-line no-debugger
    // debugger;
    if (portal === "FOREX" || portal === "LIVE") {
      switch (subPortal) {
        case "LIVE":
        case "DEMO":
          switch (step) {
            case "openAccount":
              if (!stages.individual.submitProfile) {
                dispatch(toggleCurrentModal("SubmitIndProfile"));
              } else if (!stages.openAccount) {
                dispatch(toggleCurrentModal("JourneyCreateAccount"));
              } else {
                dispatch(toggleCurrentModal("CreateAccModal"), extraParams?.type);
              }
              break;
            case "kycApproved":
              if (!stages.individual.submitProfile) {
                dispatch(toggleCurrentModal("SubmitIndProfile"));
              } else if (!stages.openAccount) {
                dispatch(toggleCurrentModal("JourneyCreateAccount"));
              } else if (!stages.kycUpload) {
                dispatch(toggleCurrentModal("UploadKycModal"));
              } else if (!stages.kycApproved && stages.kycUpload) {
                dispatch(toggleCurrentModal("KYCProgress"));
              }
              break;
            case "fiatDeposit":
            case "fiatWithdraw":
            case "Transfer":
            case "selectWithdrawalMethodModal":
            case "selectDepositMethodModal":
              if (!stages.individual.submitProfile) {
                dispatch(toggleCurrentModal("SubmitIndProfile"));
              } 
               if (!stages.openAccount) {
                dispatch(toggleCurrentModal("JourneyCreateAccount"));
              } 
               if (!stages.kycUpload) {
                dispatch(toggleCurrentModal("UploadKycModal"));
              } else if (!stages.kycApproved && stages.kycUpload) {
                dispatch(toggleCurrentModal("KYCProgress"));
              } else {
                dispatch(toggleCurrentModal(step));
              }
              break;
            case "startTrading":
              if (!stages.individual.submitProfile) {
                dispatch(toggleCurrentModal("SubmitIndProfile"));
              } else if (!stages.openAccount) {
                dispatch(toggleCurrentModal("JourneyCreateAccount"));
              } else if (!stages.kycUpload) {
                dispatch(toggleCurrentModal("UploadKycModal"));
              } else if (!stages.kycApproved && stages.kycUpload) {
                dispatch(toggleCurrentModal("KYCProgress"));
              } else if (!stages.startTrading) window.location.href = "/platforms";
              break;
          }
          break;
        case "IB":
          switch (step) {
            case "IbJourney":
              if (!stages.individual.submitProfile) {
                dispatch(toggleCurrentModal("SubmitIndProfile"));
              } else if (!stages.ib.ibQuestionnaire) {
                dispatch(toggleCurrentModal("IBQuestionnaire"));
              } else if (!stages.kycUpload) {
                dispatch(toggleCurrentModal("UploadKycModal"));
              } else if (!stages.kycApproved && stages.kycUpload) {
                dispatch(toggleCurrentModal("KYCProgress"));
              }
            break;
            case "fiatDeposit":
            case "fiatWithdraw":
            case "Transfer":
            case "selectDepositMethodModal":
              if (!stages.individual.submitProfile) {
                dispatch(toggleCurrentModal("SubmitIndProfile"));
              } else if (!stages.ib.ibQuestionnaire) {
                dispatch(toggleCurrentModal("IBQuestionnaire"));
              } else if (!stages.kycUpload) {
                dispatch(toggleCurrentModal("UploadKycModal"));
              } else if (!stages.kycApproved && stages.kycUpload) {
                dispatch(toggleCurrentModal("KYCProgress"));
              } else {
                dispatch(toggleCurrentModal(step));
              }
              break;
          }
          break;
        case "INVESTOR":
          switch (step) {
            case "fiatDeposit":
            case "fiatWithdraw":
            case "LinkMasterModal":
            case "UnlinkMasterModal":
            case "CreateInvestorAccountModal":
            case "selectDepositMethodModal":
            case "Transfer":
            case "Invest":
              if (!stages.individual.submitProfile) {
                dispatch(toggleCurrentModal("SubmitIndProfile"));
              } else if (!stages.investor?.openAccount || step === "CreateInvestorAccountModal") {
                dispatch(toggleCurrentModal("CreateInvestorAccountModal"));
              } else if (!stages.kycUpload) {
                dispatch(toggleCurrentModal("UploadKycModal"));
              } else if (!stages.kycApproved && stages.kycUpload) {
                dispatch(toggleCurrentModal("KYCProgress"));
              } else if (!stages.madeDeposit) {
                dispatch(toggleCurrentModal("selectDepositMethodModal"));
              } else {
                dispatch(toggleCurrentModal(step));
              }
              break;
              case "selectWithdrawalMethodModal":
                if (!stages.individual.submitProfile) {
                  dispatch(toggleCurrentModal("SubmitIndProfile"));
                } else if (!stages?.openAccount) {
                  dispatch(
                    toggleCurrentModal("CreateInvestorAccountModal")
                  );
                } else if (!stages.kycUpload) {
                  dispatch(toggleCurrentModal("UploadKycModal"));
                } else if (!stages.kycApproved && stages.kycUpload) {
                  dispatch(toggleCurrentModal("KYCProgress"));
                } else {
                  dispatch(toggleCurrentModal(step));
                }
                break;
              case "investorJourney":
                if (!stages.individual.submitProfile) {
                  dispatch(toggleCurrentModal("SubmitIndProfile"));
                } else if (!stages.investor?.openAccount) {
                  dispatch(toggleCurrentModal("CreateInvestorAccountModal"));
                } else if (!stages.kycUpload) {
                  dispatch(toggleCurrentModal("UploadKycModal"));
                } else if (!stages.kycApproved && stages.kycUpload) {
                  dispatch(toggleCurrentModal("KYCProgress"));
                } else if (!stages.madeDeposit) {
                  dispatch(toggleCurrentModal("selectDepositMethodModal"));
                } else if (!stages.investor.linkAccount && stages.investor.requestLink) {
                  return dispatch(toggleCurrentModal("LinkProgress"));
                } else if (!stages.investor.linkAccount) {
                  dispatch(toggleCurrentModal("LinkMasterModal"));
                } else {
                  dispatch(toggleCurrentModal(step));
                }
                break;
                
          }
          break;
        case "SP":
          switch (step) {
            case "spJourney":
              if (!stages.individual.submitProfile) {
                dispatch(toggleCurrentModal("SubmitIndProfile"));
              } else if (!stages.kycUpload) {
                dispatch(toggleCurrentModal("UploadKycModal"));
              }  else if (!stages.kycApproved && stages.kycUpload) {
                dispatch(toggleCurrentModal("KYCProgress"));
              } else if (!stages.sp.requestProfile) {
                dispatch(toggleCurrentModal("NewMamProfile"));
              } else if (stages.sp.requestProfile && !stages.sp.configureProfile) {
                dispatch(toggleCurrentModal("ProfileRequest"));
              } else if (!stages.startTrading) {
                dispatch(toggleCurrentModal("StartTradingModal"));
              }
              break;
              
            case "Transfer":
            case "fiatDeposit":
            case "fiatWithdraw":
            case "selectWithdrawalMethodModal":
            case "selectDepositMethodModal":
            case "NewMamProfile":
            case "ProfileRequest":
              if (!stages.individual.submitProfile) {
                dispatch(toggleCurrentModal("SubmitIndProfile"));
              } else if (!stages.sp.requestProfile) {
                dispatch(toggleCurrentModal("NewMamProfile"));
              }  else if (!stages.kycUpload) {
                dispatch(toggleCurrentModal("UploadKycModal"));
              } else if (!stages.kycApproved && stages.kycUpload) {
                dispatch(toggleCurrentModal("KYCProgress"));
              } else if (stages.sp.requestProfile && !stages.sp.configureProfile) {
                dispatch(toggleCurrentModal("ProfileRequest"));
              } else {
                dispatch(toggleCurrentModal(step));
              }
              break;
              // if (!stages.individual.submitProfile) {
              //   dispatch(toggleCurrentModal("SubmitIndProfile"));
              // } else if (!stages.sp.configureProfile) {
              //   dispatch(
              //     toggleCurrentModal("NewMamProfile")
              //   );
              // } else if (!stages.kycUpload) {
              //   dispatch(toggleCurrentModal("UploadKycModal"));
              // } else if (!stages.kycApproved && stages.kycUpload) {
              //   dispatch(toggleCurrentModal("KYCProgress"));
              // } else {
              //   dispatch(toggleCurrentModal(step));
              // }
              // break;
          }
        break;
      }
    }
};
